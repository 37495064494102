<template>
	<div id="messaging-widget" class="card card-custom rounded-xl gutter-b">
		<div class="d-flex justify-content-between h-100">
			<div class="col-lg-8 d-flex flex-column justify-content-between">
				<div id="message-title">
					<h1>{{ title }}</h1>
				</div>
				<div class="pb-3">
					<h4>{{ subTitle }}</h4>
					<a href="https://www.itcglobal.com/technology/managed-services/" target="blank">Learn more</a>
				</div>
			</div>
			<div id="itc-sat">
				<img src="/media/logos/itc-sat-outline.png" alt="ITC Satellite outline" height="420px" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		subTitle: {
			type: String,
			required: false,
		},
	},
};
</script>

<style lang="scss" scoped>
#messaging-widget {
	background-color: #fdc82f;
	height: 250px;
	padding: 15px;

	#message-title > h1 {
		font-size: 3.5em;
	}
	#itc-sat {
		position: absolute;
		right: 15px;
		height: 235px;
		overflow: hidden;
	}
}

@media (max-width: 991.98px) {
	#messaging-widget {
		height: 200px;

		#itc-sat {
			height: 170px;

			img {
				height: 350px;
			}
		}
	}
}
</style>
